var site = site || {};

(function ($) {
  Drupal.behaviors.productBenefitsV1 = {
    attach: function (context) {
      var self = this;

      $(function () {
        self.init(context);
      });
    },

    init: function (context) {
      var $module = $('.js-product-benefits-v1:not(.attached)', context);
      var productBenefits = site.translations?.product_benefits;

      $module.each(function () {
        var $me = $(this);
        var $results = $('.js-benefits', $me);
        var tags = $(this).data('benefits');
        var rendered = {};

        $me.addClass('attached');
        tags = tags.split(',').map((tag) => tag.trim());

        _.each(tags, function (tag) {
          if (productBenefits?.[tag]) {
            rendered = site.template.get({
              name: 'product_benefits_item',
              data: productBenefits[tag]
            });

            $results.append(rendered);
          }
        });
        // If we have tags, show the module
        if (tags.length > 0) {
          $me.removeClass('hidden');
        }
      });

      $(document).trigger('benefits.loaded');
    }
  };
})(jQuery);
